/*
  Theme Name: Imperial
  Theme URL: https://bootstrapmade.com/imperial-free-onepage-bootstrap-template/
  Author: BootstrapMade
  Author URL: https://bootstrapmade.com
*/
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body text-paragraph leading-6 bg-gray-200;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-white font-primary;
  }
  .section {
    @apply py-12 lg:py-32;
  }
  .section-title {
    @apply text-3xl lg:text-4xl font-medium lg:font-extrabold mb-5;
  }
  .subtitle {
    @apply text-base text-paragraph mb-16 lg:mb-24 max-w-[520px];
  }
  .btn {
    @apply py-4 px-7 font-medium text-white flex items-center justify-center rounded-sm hover:bg-accent-hover transition-all;
  }
  .btn-lg {
    @apply h-[54px];
  }
  .btn-md {
    @apply h-[48px];
  }
  .input {
    @apply bg-secondary text-paragraph h-[60px] outline-none pl-6 w-full font-body text-[15px] rounded-sm focus:outline focus:outline-1 focus:outline-accent;
  }
  .textarea {
    @apply bg-secondary resize-none w-full outline-none p-6 rounded-sm h-[200px] focus:outline focus:outline-1 focus:outline-accent;
  }
  .active {
    @apply text-accent;
  }
  
}



/* Please ❤ this if you like it! */


@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

.link {
  color: #c4c3ca;
}
.link:hover {
  color: #ffeba7;
}
.over-hide {
  overflow: hidden;
}
.size-22{
  font-size: 22px;
}
.section{
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;

}
.full-height{
  min-height: 100vh;
}
.bg-black{
  background-color: rgb(0 0 0 / 85%);
}


.mrgb15x {
  margin-bottom: 150px;
}
.mrgt15x {
  margin-top: 150px;
}
.mrgt8x {
  margin-top: 80px;
}
.mrgb4x {
  margin-bottom: 40px;
}
.section-heading {
  position: relative;
}
.text-center {
  text-align: center;
}
.section-heading h3 {
  letter-spacing: 4px;
  position: relative;
  text-transform: uppercase;
}
.letterspace4x {
  letter-spacing: 4px;
}
.section-heading.blue-border h3::after {
  border-bottom: 3px solid #c06b2c;
  bottom: -15px;
  content: "";
  display: block;
  height: 3px;
  margin: 0 auto;
  position: absolute;
  width: 42px;
  left: 48%;
}
.section-heading p {
  font-size: 16px;
  margin-top: 44px;
}
.nav-pills > li + li {
  margin-left: 0;
}
.nav-pills > li + li {
  margin-left: 0;
}
/*..................PRICING TABLE SECTION...........*/
.plan-option .nav.nav-pills li a {
  border: 1px solid #ebf0f2;
  color: #232323;
  font-size: 12px;
  padding: 18px 35px;
  border-radius: 0px;
}
.plan-option .nav.nav-pills .active a {
border-color: #42a5f6;
background-color: #42a5f6;
color: #fff;
}
.plan-option .nav.nav-pills li:last-child a {
border-left: none;
}
.plan-option .nav.nav-pills {
display: inline-block;
}
.plan-option .nav.nav-pills li {
position: relative;
}
.nav-pills > li + li {
margin-left: 0;
}
.plan-option .or-crircle {
  background-color: #42a5f6;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
  color: #fff;
  display: block;
  font-size: 9px;
  height: 25px;
  line-height: 27px;
  text-align: center;
  width: 25px;
  position: absolute;
  top: 14px;
  left: -14px;
  z-index: 1;
}
.pricing-table p {
font-size: 14px;
color: #777777;
margin-bottom: 40px;
margin-top: 10px;
}
.plan-price h1 {
font-size: 50px;
display: inline-block;
margin-right: 5px;
}
.plan-price h1 sup {
top: -0.4em;
font-size: 28px;
}
.plan-price sub {
font-size: 12px;
color: #c4c4c4;
display: inline-block;
font-family: 'PT Sans';
bottom: 0.25em;
}
div.lower-detail > ul {
  flex-direction: column;
}
.plan-price span.underline {
  border-bottom: 2px solid #c06b2c;
  display: block;
  margin-bottom: 10px;
  padding: 10px 0;
  text-align: left;
  width: 22px;
}
.upper-detail {
position: relative;
padding-top: 50px;
}
.plan-price {
  padding-bottom: 43px;
  position: relative;
}
.lower-detail ul > li {
border-bottom: 1px solid #f0f0f0;
margin: 0;
padding: 20px 0;
list-style:none;
}
.lower-detail ul li:last-child {
border-bottom:none;
}
.btn-get {
  padding: 15px 42px;
  border: 2px solid #c06b2c;
  background: transparent;
  color: #ffffff;
  border-radius: 15px;
  font-size: 12px;
  letter-spacing: 2px;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 0;
}
.outer-border:hover .btn-get {
border-color: #ffffff;
color: #c06b2c;
}
section.ser {
  margin-top: -107px;
}
.upper-detail::after {
  border-left: 9.1rem solid #121212;
  border-right: 9.1rem solid #121212;
  border-top: 30px solid #c06b2c;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 0.4s ease-in 0s;
}
#monthly .row {
  display: inline-flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.plan-price::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
}
.pricing-table {
  border: 0;
  background: #121212;
  border-radius: 15px;
}
.outer-border {
  border: 8px solid #121212;
  border-radius: 31px;
  background: #121212;
}
.new-tag span {
font-size: 12px;
background-color: #070707;
color: #fff;
font-family: 'PT Sans';
padding: 6px 15px;
}
.new-tag {
  position: absolute;
  right: 0;
  top: 5px;
}
.lower-detail {
  margin-top: 25px;
  padding: 0 50px;
  margin-bottom: 15px;
}
.outer-border:hover .upper-detail {
background-color: #070707;
}
.outer-border:hover .upper-detail::after {
border-top-color: #070707;
}
.outer-border:hover {
  border-color: #070707;
  box-shadow: 0 0 12px #0e0e0e;
  
}
#contato{
  padding: 80px 20px;
  background: #fff0;
  margin-bottom: 64px;
  display: table;
  width: 100%;
  height: 100%;
}

#contato .flex.flex-col.items-center.text-center {
  transition: transform 0.3s ease-in-out;
  border-radius: 15px;
  text-align: center;
}

ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}
li.cursor-pointer.capitalize.m-4 {
  list-style-type: none !important;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
li.cursor-pointer.capitalize.m-4:hover {
  transform: scale(1.1);
  color: #3464ac;
}

.container-max {
  max-width: 1685px;
}
#portfolio .flex.flex-col.items-center.text-center {
  transition: transform 0.3s ease-in-out;
  border-radius: 15px;
  text-align: center;
}
.contato .flex {
  align-items: center;
}
:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fef8f8;
}
.projetos {
  padding: 20px;
}
.lg\:hidden ul.right-0.fixed.top-0.bottom-0.w-full.flex.flex-col.justify-center.items-center.transition-all.duration-300.overflow-hidden a{
  color: #fff;
}
.lg\:hidden ul.right-0.fixed.top-0.bottom-0.w-full.flex.flex-col.justify-center.items-center.transition-all.duration-300.overflow-hidden .flex {
  display: flex
;
  flex-direction: row;
}

@media (min-width: 1024px) {
  .lg\:grid-cols-3 {
      grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 2rem;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 100%;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {
  width: 100%;
  height: auto;
  border-radius: 5px 5px 85px 85px;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: #0e0e0e;      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: #0e0e0e;
  transform: translateY(-100%);
  transition: .2s ease-in-out;
  justify-content: space-evenly;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: #0e0e0e;
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #c06b2c;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #7e4c27;  
}

.card__status {
  font-size: .8em;
  color: #7e4c27;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #fff;
  font-family: "MockFlowFont";   
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}    
.btn-md.bg-accent.hover\:bg-secondary-hover.transition-all {
  border-radius: 15px;
  height: 20px;
  position: absolute;
  right: 3pc;
  top: 3pc;
  font-size: 14px;
}
#portfolio .flex.flex-col.items-center.text-center:hover {
  transform: scale(0.9);
  cursor: pointer;
}
ul.flex.flex-col.md\:flex-row.justify-evenly.items-center.text-white {
  background: #0e0e0e94;
  border-radius: 15px;
}
@media (min-width: 768px) {
  ul {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
p.text-accent.font-normal {
  margin: 0;
}
.rounded-2xl {
  border-radius: 1rem;
}
.w-full{
  width: 100%;
}
body {
  background: #0a0a0a;
  color: #666666;
  font-family: "Open Sans", sans-serif;
}
body{
  margin: 0px;
}
a {
  color: #ac6b34;
  transition: 0.5s;
}

a:hover, a:active, a:focus {
  color: #ac6b34;
  outline: none;
  text-decoration: none;
}
.strong-name{
  color:#ac6b34;
  font-size: 44px;
}
img.logo{
  width: 80px;
}
header.bg-tertiary.h-20.flex.items-center.fixed.top-0.w-full.text-white.z-10.transition-all.duration-300 {
  background: #000000ad;
}
p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Prelaoder */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff url("./assets/img/eu.jpg") no-repeat center center;
}

/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

.back-to-top:focus {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #03C4EB;
  color: #fff;
}

/*--------------------------------------------------------------
# Welcome
--------------------------------------------------------------*/
#hero {
  display: table;
  width: 100%;
  height: 100vh;
}
#hero .hero-logo {
  margin: 20px;
}

#hero .hero-logo img {
  max-width: 100%;
}

#hero .hero-container {
  display: table-cell;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

#hero h1 {
  margin: 30px 0 10px 0;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  color: #fff;
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
}

#hero h2 {
  color: #999;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  #hero h2 .rotating {
    display: block;
  }
}

#hero .rotating > .animated {
  display: inline-block;
}

#hero .actions a {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none !important;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
}

#hero .btn-get-started {
  background: #ac6b34;
  border: 2px solid #ac6b34;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: none;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-services {
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-services:hover {
  background: #ac6b34;
  border: 2px solid #ac6b34;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #111;
  padding: 20px 0;
  height: 90px;
}

#header #logo {
  float: left;
}

#header #logo h1 {
  font-size: 36px;
  margin: 0;
  padding: 6px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a, #header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
  max-height: 50px;
}

@media (max-width: 768px) {
  #header {
    height: 80px;
  }
  #header #logo h1 {
    font-size: 26px;
  }
  #header #logo img {
    max-height: 40px;
  }
}

.is-sticky #header {
  background: rgba(0, 0, 0, 0.85);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 5px 0;
}

@media (max-width: 768px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 10px 15px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 14px;
  outline: none;
}

.nav-menu a:hover, .nav-menu li:hover > a, .nav-menu .menu-active > a {
  color: #ac6b34;
}

.nav-menu ul {
  margin: 4px 0 0 15px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.nav-menu ul li {
  background: #fff;
  border-top: 1px solid #f4f4f4;
}

.nav-menu ul li:first-child {
  border-top: 0;
}

.nav-menu ul li:hover {
  background: #f6f6f6;
}

.nav-menu ul li a {
  color: #333;
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
}

#mobile-nav-toggle i {
  color: #fff;
}

@media (max-width: 768px) {
  #mobile-nav-toggle {
    display: inline;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #03C4EB;
}

#mobile-nav ul .menu-item-active {
  color: #03C4EB;
}
footer .container {
  text-align: center;
  max-width: 100%;
}
#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
/* Sections Common
--------------------------------*/

.section-description {
  text-align: center;
  margin-bottom: 40px;
}

.section-title-divider {
  width: 50px;
  height: 3px;
  background: #03C4EB;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Get Started Section
--------------------------------*/
#about {
  background: #fff0;
  padding: 80px 0;
}


#about .about-content {
  background: #fff0;
}

#about .about-title {
  color: #333;
  font-weight: 700;
  font-size: 28px;
}

#about .about-text {
  line-height: 26px;
}

#about .about-text:last-child {
  margin-bottom: 0;
}

/* Services Section
--------------------------------*/
#services {
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) fixed center center;
  background-size: cover;
  padding: 80px 0 60px 0;
}

#services .service-item {
  margin-bottom: 20px;
}

#services .service-icon {
  float: left;
  background: #03C4EB;
  padding: 16px;
  border-radius: 50%;
  transition: 0.5s;
  border: 1px solid #03C4EB;
}

#services .service-icon i {
  color: #fff;
  font-size: 24px;
}

#services .service-item:hover .service-icon {
  background: #fff;
}

#services .service-item:hover .service-icon i {
  color: #03C4EB;
}

#services .service-title {
  margin-left: 80px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
}

#services .service-title a {
  color: #111;
}

#services .service-description {
  margin-left: 80px;
  line-height: 24px;
}

/* Subscribe Section
--------------------------------*/
#subscribe {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) fixed center center;
  background-size: cover;
  padding: 80px 0;
}

#subscribe .subscribe-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#subscribe .subscribe-text {
  color: #fff;
}

#subscribe .subscribe-btn-container {
  text-align: center;
  padding-top: 20px;
}

#subscribe .subscribe-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#subscribe .subscribe-btn:hover {
  background: #03C4EB;
  border: 2px solid #03C4EB;
}

/* Portfolio Section
--------------------------------*/
#portfolio {
  background: #fff0;
  padding: 80px 20px;
  margin-bottom: 64px;
  display: table;
  width: 100%;
  height: 100vh;
}

#portfolio .portfolio-item {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 260px;
  width: 100%;
  display: table;
  overflow: hidden;
  margin-bottom: 30px;
}

#portfolio .portfolio-item .details {
  height: 260px;
  background: #fff;
  display: table-cell;
  vertical-align: middle;
  opacity: 0;
  transition: 0.3s;
  text-align: center;
}

#portfolio .portfolio-item .details h4 {
  font-size: 16px;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
  font-weight: 700;
  color: #333333;
}

#portfolio .portfolio-item .details span {
  display: block;
  color: #666666;
  font-size: 13px;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
}

#portfolio .portfolio-item:hover .details {
  opacity: 0.8;
}

#portfolio .portfolio-item:hover .details h4 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#portfolio .portfolio-item:hover .details span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Testimonials Section
--------------------------------*/
#testimonials {
  background: #f6f6f6;
  padding: 80px 0;
}

#testimonials .profile {
  text-align: center;
}

#testimonials .profile .pic {
  border-radius: 50%;
  border: 6px solid #fff;
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
  width: 260px;
}

#testimonials .profile .pic img {
  max-width: 100%;
}

#testimonials .profile h4 {
  font-weight: 700;
  color: #03C4EB;
  margin-bottom: 5px;
}

#testimonials .profile span {
  color: #333333;
}

#testimonials .quote {
  position: relative;
  background: #fff;
  padding: 60px;
  margin-top: 40px;
  font-size: 16px;
  font-style: italic;
  border-radius: 5px;
}

#testimonials .quote b {
  display: inline-block;
  font-size: 22px;
  left: -9px;
  position: relative;
  top: -8px;
}

#testimonials .quote small {
  display: inline-block;
  right: -9px;
  position: relative;
  top: 4px;
}

/* Team Section
--------------------------------*/
#team {
  background: #fff;
  padding: 80px 0 60px 0;
}

#team .member {
  text-align: center;
  margin-bottom: 20px;
}

#team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
}

#team .member .pic img {
  max-width: 100%;
}

#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
}

#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

#team .member .social {
  margin-top: 15px;
}

#team .member .social a {
  color: #b3b3b3;
}

#team .member .social a:hover {
  color: #ac6b34;
}

#team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Contact Section
--------------------------------*/
#contact {
  background: #f7f7f7;
  padding: 80px 0;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  font-size: 32px;
  color: #03C4EB;
  float: left;
}

#contact .info p {
  padding: 0 0 10px 50px;
  line-height: 24px;
}

#contact .form #sendmessage {
  color: #03C4EB;
  border: 1px solid #03C4EB;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input, #contact .form textarea {
  border-radius: 0;
  box-shadow: none;
}

#contact .form button[type="submit"] {
  background: #03C4EB;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

#contact .form button[type="submit"]:hover {
  background: #25d8fc;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff0;
  padding: 30px 0;
  color: #fff;
}

#footer .copyright {
  text-align: center;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #ccc;
}

.swiper-pagination {
  margin-top: 20rem;
}

.swiper-pagination-bullet {
  background-color: #3464ac;
}





html, body {
  font-family: 'Dongle', sans-serif;
  margin: 0;
  padding: 0;
}

.text-container {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 96px;
  color: white;
  opacity: 0.8;
  user-select: none;
  text-shadow: 1px 1px rgba(0,0,0,0.1);
}

:root {
  --color-bg1: rgb(0, 0, 0);
  --color-bg2: rgb(0, 0, 0);
  --color1: 18, 113, 255;
  --color2: 221, 74, 255;
  --color3: 100, 220, 255;
  --color4: 200, 50, 50;
  --color5: 180, 180, 50;
  --color-interactive: 140, 100, 255;
  --circle-size: 80%;
  --blending: hard-light;
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}


.gradient-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -9999;
  overflow: hidden;
  filter: blur(15px);
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;

  svg {
    position: fixed;
    top:0;
    left:0;
    width: 0;
    height: 0;
  }


  .gradients-container {
    filter: url(#goo) blur(40px) ;
    width: 100%;
    height: 100%;
  }

  .g1 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: center center;
    animation: moveVertical 30s ease infinite;

    opacity: 1;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 400px);
    animation: moveInCircle 20s reverse infinite;

    opacity: 1;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);

    transform-origin: calc(50% + 400px);
    animation: moveInCircle 40s linear infinite;

    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;

    opacity: 0.7;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 20s ease infinite;

    opacity: 1;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}
